window.addEventListener("load", () => {
  new clipboard('.copy-button');
  $("#testrun_test_id").change( function() {
    console.log("test id changed to " + this.value)
    $.getJSON( $(this).attr("data-question-set-url").replace("TID", this.value), function( question_sets ) {
      console.log(question_sets)
      const qsc = $("#question_set_container");
      qsc.empty();

      $.each(question_sets, (idx, question) => {
        console.log(question);
        let tr = $("<tr>");
        let td0 = $("<td>");
        let qh = $("<input>")
          .attr("type", "hidden")
          .attr("name", "question_sets["+question.id+"]")
          .attr("value", 0)
        let qs = $("<input>")
          .attr("type", "checkbox")
          .attr("name", "question_sets["+question.id+"]")
          .attr("value", 1)
          .attr("id", "testrun_question_sets_"+question.id+"_selected")
        td0.append(qh);
        td0.append(qs);
        let td1 = $("<td>").text(question.title);
        tr.append(td0);
        tr.append(td1);
        qsc.append(tr);
      });
      $("#question_sets").show();

    });
  });
  $('.select-all-testees').click( (event) =>  {
    $(".testee-check").prop("checked", true);
  });
  $('.select-no-testees').click( (event) =>  {
    $(".testee-check").prop("checked", false);
  });
});
