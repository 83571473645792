const animateCSS = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    element.one('animationend', (event) => {
      event.stopPropagation();
      element.removeClass(`${prefix}faster ${prefix}animated ${prefix}` + animation);
      resolve('Animation ended');
    });

    element.addClass(`${prefix}faster ${prefix}animated ${prefix}` + animation);
  });

function next_task( st ) {
  const next_subtask = st.next(".subtask");
  animateCSS( st, "fadeOutLeftBig" ).then( (m) => { st.hide() });

  if (next_subtask.length > 0) {
    next_subtask.show();
    animateCSS( next_subtask, "fadeInRightBig" );
  } else {
    const coll = st.parents(".st-collapse");
    const card = coll.parents(".na-card");
    const next_card = card.next(".na-card");
    next_card.find(".st-collapse").collapse('show');
    next_card.find(".subtask:first").show();
  }
}

window.addEventListener("load", () => {
  const st0_form = $(".na-st0-form");
  st0_form.on("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    const card = $(event.target).parents(".card");
    const ind = card.find(".st1-indicator");
    ind.removeClass("far task-circle-incomplete").addClass("fas task-circle-complete");
    const next_card = card.next(".na-card");
    next_card.find(".st-collapse").collapse('show');
    next_card.find(".subtask:first").show();
  });

  const st1_form = $(".na-st1-form");
  st1_form.on("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    const st1 = $(event.target).parent();

    const card = st1.parents(".na-card");
    card.find('.target-image-td').addClass("target-image-frame");
    const ind = st1.parents(".na-card").find(".st1-indicator");
    ind.removeClass("far task-circle-incomplete").addClass("fas task-circle-complete");

    next_task(st1);
  });


  const st2_form = $(".na-st2-form");
  st2_form.on("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    const st2 = $(event.target).parent();
    const st3 = st2.siblings(".subtask3");

    animateCSS( st2, "fadeOutLeftBig" ).then( (m) => { st2.hide() });
    st3.show();
    animateCSS( st3, "fadeInRightBig" );

    const card = st2.parents(".na-card");
    const ind = card.find(".st2-indicator");
    ind.removeClass("far task-circle-incomplete").addClass("fas task-circle-complete");
    next_task(st2);
  });

  const st3_form = $(".na-st3-form");
  st3_form.on("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    const st3 = $(event.target).parent();
    const st4 = st3.siblings(".subtask4");

    animateCSS( st3, "fadeOutLeftBig" ).then( (m) => { st3.hide() });
    st4.show();
    animateCSS( st4, "fadeInRightBig" );

    const card = st3.parents(".na-card");
    const ind = card.find(".st3-indicator");
    ind.removeClass("far task-circle-incomplete").addClass("fas task-circle-complete");
    next_task(st3);
  });

  const st4_form = $(".na-st4-form");
  st4_form.on("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    const st4 = $(event.target).parent();
    const card = st4.parents(".na-card");
    const ind = card.find(".st4-indicator");
    ind.removeClass("far task-circle-incomplete").addClass("fas task-circle-complete");
    next_task(st4);
  });

//  $(':radio[name="st3-choice"]').change( function()  {
//    console.log("st3 change");
//    if( this.value < 4 ) {
//      $(this).parent().siblings(".st3-comment").show();
//      $(".st3-comment-area").prop("required", true);
//    } else {
//      $(this).parent().siblings(".st3-comment").hide();
//      $(".st3-comment-area").prop("required", false);
//    }
//  });
//  element.addEventListener("ajax:error", () => {
//    console.log("st1 error");
//    element.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
//  });
  
  $('.select-all-subtasks').click( (event) =>  {
    $(event.target).parent().siblings(".subtask-td").find('.subtask-check').prop("checked", true);
  });
  $('.select-no-subtasks').click( (event) =>  {
    $(event.target).parent().siblings(".subtask-td").find('.subtask-check').prop("checked", false);
  });
  $('#occupation-select').change( function()  {
    if($(this).val() == "Sonstiges") {
      $("#other-occupation-field").show();
      $("#tester-other-occupation").prop("required", true);
    } else {
      $("#other-occupation-field").hide();
      $("#tester-other-occupation").prop("required", false);
    }
  });
});
