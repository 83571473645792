// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
global.clipboard = require("clipboard")

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


require("bootstrap");
require("popper.js");


require("datatables.net-responsive-bs4/css/responsive.bootstrap4.css");
require("datatables.net-bs4/css/dataTables.bootstrap4.css");

require('imports-loader?define=>false,this=>window!datatables.net')(window, jQuery)
require('imports-loader?define=>false,this=>window!datatables.net-bs4')(window, jQuery)
require('imports-loader?define=>false,this=>window!datatables.net-responsive')(window, jQuery)
require('imports-loader?define=>false,this=>window!datatables.net-responsive-bs4')(window, jQuery)

require("kovarp-jquery-cookiebar/jquery.cookieBar")
require("lazysizes")

require("animate.css/animate.min.css");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
import "../src/style.scss";
import "../src/application.js";
